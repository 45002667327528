export interface PlayerControllerAPI {
    destroy(): void,
    seek(value: number): void,
    pause(): void,
    play(): void
}

export interface PlayerState {
    current: number,
    duration: number,
    isPlaying: boolean,
    isPaused: boolean,
    playAvailable: boolean,
    pauseAvailable: boolean,
    seekAvailable: boolean
    error: any
}

enum STATE {
    IDLE,
    READY,
    PLAYING,
    PAUSED,
    SEEKING,
    ERROR
}

export default function PlayerController(player: HTMLAudioElement, onUpdate: (state: PlayerState) => void): PlayerControllerAPI {
    let playAvailable = false;
    let pauseAvailable = false;
    let seekAvailable = false;
    let state: STATE = STATE.IDLE;
    let error: any = null

    const sendUpdate = () => {

        const current = player.currentTime;
        const duration = player.duration;
        const isPaused = player.paused;
        const isPlaying = state === STATE.PLAYING;

        onUpdate({
            current,
            duration,
            isPaused,
            isPlaying,
            playAvailable,
            pauseAvailable,
            seekAvailable,
            error
        });
    }

    const callbacks: [string, (e: any) => void][] = [
        ['canplaythrough', () => {
            sendUpdate();
        }],
        ['timeupdate', () => {
            sendUpdate();
        }],
        ['canplay', () => {
            playAvailable = true;
            pauseAvailable = false;
            seekAvailable = true;

            sendUpdate();
        }],
        ['play', () => {
            playAvailable = false;
            pauseAvailable = true;
            seekAvailable = true;

            sendUpdate();
        }],
        ['playing', () => {
            playAvailable = false;
            pauseAvailable = true;
            seekAvailable = true;

            sendUpdate();
        }],
        ['pause', () => {
            playAvailable = true;
            pauseAvailable = false;
            seekAvailable = true;

            sendUpdate();
        }],
        ['error', (event) => {
            error = player.error?.code + ':' + player.error?.message;

            sendUpdate();
        }]
    ]

    const play = () => {
        player.play();
    }

    const pause = () => {
        player.pause();
    }

    const seek = (value: number) => {
        player.currentTime = value;
    }

    callbacks.forEach(([key, handler]) => {
        player.addEventListener(key, handler);
    });

    const destroy = () => {
        callbacks.forEach(([key, handler]) => {
            player.removeEventListener(key, handler);
        });
    }

    return {
        destroy,
        seek,
        pause,
        play
    }
}
