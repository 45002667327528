import { useCallback } from "react";
import styled from "styled-components";
import { MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main";
import { GameAction } from "../../modules/main/game-tree";


const AnswerContainer = styled.button`

    display: flex;
    align-items: stretch;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    .wrapper {
        flex: 1 1 100%;
        display: flex;
        align-items: stretch;
    }

    .inner {
        flex: 1 1 100%;
        border: 4px #0a4e71 solid;
        border-radius: 16px;

        min-height: 3em;
        padding: .5em;
        font-size: 1rem;

        background: white;

        text-align: center;

        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 34px;
            bottom: -26px;
            border: 12px solid;
            border-color: #0a4e71 transparent transparent #0a4e71;
        }

        &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 38px;
            bottom: -16px;
            border: 10px solid;
            border-color: #fff transparent transparent #fff;
        }

        @media (pointer: fine) {
            &:hover {
                background: #fc5c65;
                border-color: #fc5c65;
                color: white;

                &:before,
                &:after {
                    border-color: #fc5c65 transparent transparent #fc5c65;
                }
            }
        }
    }

    @media (max-width: 1050px) {
        grid-column: span 6;
    }

    @media (max-width: 425px) {
        grid-column: span 12;
    }
`;

type Props = {
    action: GameAction
}

const Answer = ({ action }: Props) => {

    const dispatch = useMainModuleDispatch();

    const onClick = useCallback(() => {
        dispatch({
            type: MAIN_MODULE_ACTION.MAKE_ACTION,
            payload: action
        })
    }, [action, dispatch])

    return <AnswerContainer onClick={onClick}>
        <div className="wrapper">
            <div className="inner">
                {action.text}
            </div>
        </div>
    </AnswerContainer>
}

export default Answer;
