import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

const CONFIG_MODULE_LS_KEY = 'game_data'

export type MainModuleState = {
    activeNode: string,
    lastAction?: string
}

export const initMainModuleState = {
    activeNode: '1'
}

export enum MAIN_MODULE_ACTION {
    MAKE_ACTION,
    RESET
}

export type MainModuleAction = {
    type: MAIN_MODULE_ACTION,
    payload?: any
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = makeStore(reducer, initMainModuleState, CONFIG_MODULE_LS_KEY)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}
