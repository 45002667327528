import { useCallback, useReducer, useRef } from "react";
import styled from "styled-components";
import { MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main";
import { gameTree } from "../../modules/main/game-tree";
import menuSrc from '../../assets/menu.png';
import menuHoverSrc from '../../assets/menu-hover.png';

const Container = styled.div`
    position: absolute;
    z-index: 20;

    .menu-button {

        background: none;
        border: none;
        width: 60px;
        height: 50px;

        cursor: pointer;

        &.closed {
            background: url(${menuSrc}) 50% 50% no-repeat white;
        }

        &.open {
            background: url(${menuHoverSrc}) 50% 50% no-repeat #b9c5c5;
        }

        &:hover {
            background: url(${menuHoverSrc}) 50% 50% no-repeat #0a4e71;
        }
    }

    .menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        background: #b9c5c5;

        width: 200px;

        position: absolute;
        top: 50px;
        padding: .2em;

        &>* {
            padding: .2em;
        }

        button {
            border: 1px solid #0a4e71;
            background: white;
            cursor: pointer;
            font-size: 1rem;
            width: 100%;
            padding: .3em 0;

            &:hover {
                background: #0a4e71;
                color: white;
            }
        }

        select {
            font-size: 1rem;
            width: 100%;
            padding: .2em;
        }
    }
`;


const MainMenu = () => {

    const dispatch = useMainModuleDispatch();

    const nodeSelectRef = useRef<HTMLSelectElement>(null);

    const [showMenu, toggle] = useReducer((state) => {
        return !state;
    }, false)

    const toggleFS = useCallback(() => {
        if (!document.documentElement.requestFullscreen || !document.exitFullscreen) {
            return;
        }

        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
        toggle();
    }, [toggle]);

    const goToNode = useCallback(() => {
        if (nodeSelectRef.current) {
            dispatch({
                type: MAIN_MODULE_ACTION.MAKE_ACTION,
                payload: {
                    target: nodeSelectRef.current.value || '1'
                }
            });
        }
        toggle();
    }, [dispatch, toggle]);

    return <Container>
        <button onClick={toggle} className={`menu-button ${showMenu ? 'open' : 'closed'}`}></button>
        {
            showMenu && <div className="menu">
                <div style={{ gridColumn: 'span 3' }}>
                    <button onClick={toggleFS}>Pełny ekran</button>
                </div>
                <div style={{ gridColumn: 'span 2' }}>
                    <select ref={nodeSelectRef}>
                        {
                            gameTree.map(node => {
                                return <option key={node.id} value={node.id}>{node.id}</option>
                            })
                        }
                    </select>
                </div>
                <div style={{ gridColumn: 'span 1' }}>
                    <button onClick={goToNode}>Idź do</button>
                </div>
            </div>
        }
    </Container>
}

export default MainMenu;
