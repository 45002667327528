import styled from "styled-components"

import imgSrc from '../../assets/img.png';

import blcSrc from '../../assets/blc.png';
import brcSrc from '../../assets/brc.png';
import tlcSrc from '../../assets/tlc.png';
import trcSrc from '../../assets/trc.png';
import dziubekSVGSrc from '../../assets/dziubek.svg';
import BasicWrapper from "./basic-wrapper";

const Container = styled.div`
    position: relative;

    flex: 0 1 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 200px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 33%;
        left: 0;
        right: 0;
        bottom: 33%;

        background: #eaecec;
        box-shadow: inset 0 2px 5px 0 rgba(0,0,0,.13);
    }
`;

const CustomBasicWrapper = styled(BasicWrapper)`
    flex: 0 1 100%;
    display: flex;
    justify-content: 'center';
    align-items: 'streach';
`;

const ImageContainer = styled.div`
    max-width: 512px;
    width: 100%;
    margin: 0 auto;

    flex: 1 1 100%;

    display: flex;
    justify-content: center;
    align-items: stretch;

.img {
    width: 100%;
    background: url(${imgSrc}) 50% 50% no-repeat;
    background-size: cover;

    position: relative;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;

    display: grid;

    grid-template-columns: 20px 1fr 20px 1fr 20px;
    grid-template-rows: 20px 1fr 20px 10px;

    .tlc {
        background: url(${tlcSrc}) 50% 50% no-repeat;
        background-size: 100% 100%;
        grid-row: 1;
        grid-column: 1;
    }

    .trc {
        background: url(${trcSrc}) 50% 50% no-repeat;
        background-size: 100% 100%;
        grid-row: 1;
        grid-column: 5;
    }

    .blc {
        background: url(${blcSrc}) 50% 50% no-repeat;
        background-size: 100% 100%;
        grid-row: 3;
        grid-column: 1;
    }

    .brc {
        background: url(${brcSrc}) 50% 50% no-repeat;
        background-size: 100% 100%;
        grid-row: 3;
        grid-column: 5;
    }

    .mask1 {
        background: white;
        grid-row: 4;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .mask2 {
        background: white;
        grid-row: 4;
        grid-column-start: 4;
        grid-column-end: 6;
    }

    .dziubek {
        background: url(${dziubekSVGSrc}) 50% 50% no-repeat;
        background-size: auto 100%;
        grid-row: 4;
        grid-column: 3;
    }
}
`;

const GameImage = () => {
    return <Container>
        <CustomBasicWrapper>
            <ImageContainer>
                <div className="img">
                    <div className="mask">
                        <div className="tlc"></div>
                        <div className="trc"></div>
                        <div className="brc"></div>
                        <div className="blc"></div>
                        <div className="mask1"></div>
                        <div className="mask2"></div>
                        <div className="dziubek"></div>
                    </div>
                </div>
            </ImageContainer>
        </CustomBasicWrapper>
    </Container>
}

export default GameImage;
