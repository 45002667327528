import { useMemo } from "react";
import styled from "styled-components";
import { GameNode } from "../../modules/main/game-tree";
import BasicWrapper from "../../routes/home/basic-wrapper";
import Answer from "./answer";

const Container = styled(BasicWrapper)`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 1% 0 25px;
    gap: 2em 2%;

    /* &.base-4 & default */
    width: 100%;
    grid-template-columns: repeat(12, 1fr);

    &>* {
        grid-column: span 3;
    }

    @media (max-width: 1050px) {
        width: 75%;
        gap: 1em 2.5%;

        &>* {
            grid-column: span 6;
        }
    }

    @media (max-width: 425px) {
        &>* {
            grid-column: span 12;
        }
    }

    &.base-3 {
        width: 74.5%;
        grid-template-columns: repeat(9, 1fr);

        &>* {
            grid-column: span 3;
        }

        @media (max-width: 1050px) {
            width: 75%;
            gap: 30px 2.5%;
            grid-template-columns: repeat(4, 1fr);

            &>* {
                grid-column: span 2;

                &:last-child {
                    grid-column-start: 2;
                    grid-column-end: 4;
                }
            }


        }

        @media (max-width: 425px) {
            &>* {
                grid-column: span 4 !important;
            }
        }
    }

    &.base-2 {
        width: 48.6%;
        grid-template-columns: repeat(2, 1fr);

        &>* {
            grid-column: span 1;
        }

        @media (max-width: 1050px) {
            width: 75%;
            gap: 1em 2.5%;
        }

        @media (max-width: 425px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }


    &.base-1 {
        width: 23%;
        grid-template-columns: repeat(2, 1fr);

        &>* {
            grid-column: span 2;
        }

        @media (max-width: 1050px) {
            width: 75%;
            gap: 1em 2.5%;


            grid-template-columns: repeat(4, 1fr);

            &>* {
                grid-column-start: 2;
                grid-column-end: 4;
            }
        }

        @media (max-width: 425px) {
            &>* {
                grid-column: span 4;
            }
        }
    }

`

type Props = {
    gameNode: GameNode
}

const GameChoices = ({ gameNode }: Props) => {

    const base = gameNode.actions.length <= 4 ? gameNode.actions.length % 4 : 4;

    const actions = useMemo(() => {
        return gameNode.actions.map((a, index) => {
            return <Answer key={`${gameNode}${index}`} action={a} />
        })
    }, [gameNode]);

    // return <Container className={`base-1`}>
    return <Container className={`base-${base}`}>
        {actions}
    </Container>
}

export default GameChoices
