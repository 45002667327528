import { useCallback } from "react";
import styled from "styled-components"
import { MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main";

const Button = styled.button`
    background: #0a4e71;
    border: none;
    border-radius: 50%;

    cursor: pointer;

    color: white;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;

    width: 50px;
    height: 50px;
`;

const ResetButton = () => {

    const dispatch = useMainModuleDispatch();

    const onClick = useCallback(() => {
        dispatch({
            type: MAIN_MODULE_ACTION.RESET
        })
    }, [dispatch]);

    return <Button onClick={onClick}>Reset</Button>
}

export default ResetButton;
