import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import Sticky from "react-sticky-el";
import styled from "styled-components"
import { gameTree } from "../../modules/main/game-tree";
import { useNode } from "../../modules/main/hooks/use-active-node";

const Container = styled.div`

    max-width: 1200px;
    margin: 0 auto;

    display: flex;

    .index-container {
        flex: 1 1 20%;

    }


    .index {
        display: flex;
        flex-direction: column;
        padding: 0 1em;

        max-height: 100vh;
        overflow-y: scroll;
    }

    .nodes {
        flex: 1 1 100%;
    }

    .node {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        border: 1px solid black;
        margin: 1em 0;
    }

    .id {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .audio {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
    }

    .actions {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content:center;
        align-items: center;
    }

    .actions {
        grid-row: 2;
        grid-column: span 2;
        padding: .5em;
    }

    .sources {
        padding: .5em;
        background: #d9d9d9;
        grid-column: span 2;
    }
`;

const InkPage = () => {

    const tree = gameTree;

    const { hash } = useLocation();

    return <Container>
        <pre>
            {
                tree.map((node) => {
                    return <NodeInfo id={node.id} />;
                })
            }
        </pre>
    </Container>
}

export default InkPage;


function NodeInfo({ id }: { id: string }) {
    const node = useNode(id);

    const text = useMemo(() => {
        return `
=== n${node?.id} ===

xxxxxxx

${node?.actions.map(a => {
return `+ [${a.text}] -> n${a.target}`;
}).join('\n')}
        `
    }, [node]);

    return <>
        {text}
    </>
}
