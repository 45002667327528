import styled from "styled-components";

const BasicWrapper = styled.div`
    max-width: 1600px;
    width: 100%;

    margin: 0 auto;
`;

export default BasicWrapper;
