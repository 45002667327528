import { useCallback } from "react";
import styled from "styled-components";

type Props = {
    current: number,
    full: number,
    onChange: (value: number) => void
}

const Container = styled.div`
    position: relative;
    width: 100%;

    .bar {
        width: 100%;
        height: 40px;

        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            bottom: 13px;
            border-radius: 5px;

            background: #b9c5c5;
        }

        &:after {
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            right: 30%;
            bottom: 13px;
            border-radius: 5px 0 0 5px;

            background: #0a4e71;
        }


    }

    .marker-container {

        position: absolute;
            top: 0;
            left: 5px;
            right: 5px;
            bottom: 0;
    }

    .marker {
        position: absolute;
        top: 0;
        bottom: 0;

        z-index: 10;
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -5px;
            right: -5px;
            bottom: 0;
            border-radius: 5px;

            width: 10px;
            background: #336687;
        }
    }

    input {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        cursor: pointer;
        opacity: 0;
    }
`;

const Bar = styled.div<{ progress: number }>`
    width: 100%;
    height: 40px;

    position: relative;

    .empty-bar {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        bottom: 13px;
        border-radius: 5px;

        background: #b9c5c5;
    }

    .full-bar {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        bottom: 13px;
        border-radius: 5px 0 0 5px;

        background: #0a4e71;
    }
`;


const ProgressBar = ({ current, full, onChange }: Props) => {

    const onUserChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.currentTarget.value)

        if (!isNaN(value)) {
            onChange(value);
        }
    }, [onChange]);

    return <Container>
        <Bar progress={100 * current / full}>
            <div className="empty-bar"></div>
            <div className="full-bar" style={{ right: `${100 - (100 * current / full)}%` }}></div>
            <div className="marker-container">
                <div className="marker" style={{ left: `${100 * current / full}%` }}></div>
            </div>
        </Bar>

        <input type='range' min={0} max={full} step={1} onChange={onUserChange} />
    </Container>
}


export default ProgressBar;
