import styled from 'styled-components'
import AudioPlayer from '../../components/audio-player';

import Sticky from 'react-sticky-el';
import BasicWrapper from './basic-wrapper';

import useActiveNode from '../../modules/main/hooks/use-active-node';
import GameChoices from '../../components/choices';
import MainMenu from '../../components/menu';
import ResetButton from '../../components/reset-button';
import { useMemo } from 'react';
import GameImage from './image';

const Container = styled.div`
    width: 100vw;
    height: 100vh;

    min-width: 320px;

    /* background: pink; */

    display: flex;
    flex-direction: column;
    align-items: stretch;

    .dev-tools {
        position: fixed;
        top: 0;
        right: 0;
        padding: .5em;
        background: red;

        div {
            margin-bottom: .2em;
        }
    }

    .sticky {
        background: white;
        z-index: 100;
    }
`;

const ErrorScreen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
`;

export default function HomePage() {

    const { gameNode, lastAction } = useActiveNode();

    const ImageMemo = useMemo(() => {
        return <GameImage />
    }, []);

    return <>
        {gameNode && <Container>
            <TopBar>
                <BasicWrapper>
                    <MainMenu />
                    <h1>Tajemnicze Wakacje</h1>
                    <p>{lastAction}</p>
                </BasicWrapper>
            </TopBar>

            {ImageMemo}

            <GameControllsContainer>
                <Sticky>
                    <GameControlls>
                        <AudioPlayer src={gameNode.audio} />
                    </GameControlls>
                </Sticky>
            </GameControllsContainer>
            <GameChoices gameNode={gameNode} />
        </Container>}
        {!gameNode && <ErrorScreen>
            <h1>Coś poszło nie tak</h1>
            <ResetButton />
        </ErrorScreen>
        }
    </>
}


const TopBar = styled.div`
    flex: 0 0 120px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    h1 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0 auto;
        padding: 0;

        width: calc(100% - 120px);
    }

    p {
        font-family: 'Quicksand', sans-serif;
        font-size: 21px;
        margin: 0;
        padding: 0;
    }

    @media (max-width: 550px) {
        flex: 0 0 90px;
        h1 {
            font-size: 1.5rem;
        }
    }
`

const GameControllsContainer = styled(BasicWrapper)`
    flex: 0 0 250px;

    @media (orientation: landscape) and (max-height: 500px) {
            flex: 0 0 100px;
    }
`;

const GameControlls = styled.div`
    height: 250px;
    max-width: 1410px;
    margin: 0 auto;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: landscape) and (max-height: 500px) {
            height: 100px;
    }
`;
