import { MainModuleState, MainModuleAction, MAIN_MODULE_ACTION, initMainModuleState } from ".";

export default function UserReducer(state: MainModuleState, action: MainModuleAction): MainModuleState {
    const {type, payload} = action;
    switch (type) {
        case MAIN_MODULE_ACTION.MAKE_ACTION:
            return {
                ...state,
                activeNode: payload.target,
                lastAction: payload.text
            }

        case MAIN_MODULE_ACTION.RESET:
            return {...initMainModuleState}
    }

    return state;
}
