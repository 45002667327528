export type GameAction = {
    target: string,
    text?: string
}

export type GameNode = {
    id: string,
    audio: string,
    actions: GameAction[],
    text?: string
}


const CHAPTER_1: GameNode[] = [
    {
        id: '1',
        audio: '/new-audio/chapter-1/1.1.mp3',
        text: "Wstęp",
        actions: [
            {
                target: '1.13',
                text: 'Manię'
            },
            {
                target: '1.2',
                text: 'Stasia'
            }
        ]
    },
    {
        id: '1.2',
        audio: '/new-audio/chapter-1/1.2.mp3',
        text: 'Stasia',
        actions: [
            {
                target: '1.7',
                text: 'Wchodzisz na drzewo'
            },
            {
                target: '1.3',
                text: 'Pomagasz'
            }
        ]
    },
    {
        id: '1.3',
        audio: '/new-audio/chapter-1/1.3.mp3',
        text: 'Pomagasz',
        actions: [
            {
                target: '1.4',
                text: '...klikasz włącznik...'
            },
        ]
    },
    {
        id: '1.4',
        audio: '/new-audio/chapter-1/1.4.mp3',
        actions: [
            {
                target: '1.6',
                text: 'Szukasz samemu'
            },
            {
                target: '1.5',
                text: 'Idziesz do mamy'
            }
        ]
    },
    {
        id: '1.5',
        audio: '/new-audio/chapter-1/1.5.mp3',
        actions: [
            {
                target: '1.11',
                text: 'W krzaki'
            }
        ]
    },
    {
        id: '1.6',
        audio: '/new-audio/chapter-1/1.6.mp3',
        actions: [
            {
                target: '1.11',
                text: 'W krzaki'
            }
        ]
    },
    {
        id: '1.7',
        audio: '/new-audio/chapter-1/1.7.mp3',
        actions: [
            {
                target: '1.8',
                text: 'O rycerzach'
            },
            {
                target: '1.9',
                text: 'O Astronautach'
            }
        ]
    },
    {
        id: '1.8',
        audio: '/new-audio/chapter-1/1.8.mp3',
        actions: [
            {
                target: '1.10',
                text: 'BZZT!'
            }
        ]
    },
    {
        id: '1.9',
        audio: '/new-audio/chapter-1/1.9.mp3',
        actions: [
            {
                target: '1.10',
                text: 'BZZT!'
            }
        ]
    },
    {
        id: '1.10',
        audio: '/new-audio/chapter-1/1.10.mp3',
        actions: [
            {
                target: '1.11',
                text: 'W krzaki'
            }
        ]
    },
    {
        id: '1.11',
        audio: '/new-audio/chapter-1/1.11.mp3',
        actions: [
            {
                target: '1.12',
                text: 'Mani'
            },
            {
                target: '1.31',
                text: 'Mamie'
            }
        ]
    },
    {
        id: '1.12',
        audio: '/new-audio/chapter-1/1.12.mp3',
        actions: [
            {
                target: '1.26',
                text: 'Idziesz na skrzyżowanie'
            }
        ]
    },
    {
        id: '1.13',
        audio: '/new-audio/chapter-1/1.13.mp3',
        actions: [
            {
                target: '1.14',
            },
            {
                target: '1.18',
            }
        ]
    },
    {
        id: '1.14',
        text: 'Restauracja!',
        audio: '/new-audio/chapter-1/1.14.mp3',
        actions: [
            {
                target: '1.15',
                text: 'Idziesz po wodę'
            }
        ]
    },
    {
        id: '1.15',
        audio: '/new-audio/chapter-1/1.15.mp3',
        actions: [
            {
                target: '1.16',
                text: 'Prosisz tatę'
            },
            {
                target: '1.17',
                text: 'Szukasz sama'
            }
        ]
    },
    {
        id: '1.16',
        audio: '/new-audio/chapter-1/1.16.mp3',
        text: 'Prosisz tatę',
        actions: [
            {
                target: '1.22',
                text: 'Szukasz dalej'
            }
        ]
    },
    {
        id: '1.17',
        audio: '/new-audio/chapter-1/1.17.mp3',
        text: 'Szukasz sama',
        actions: [
            {
                target: '1.22'
            }
        ]
    },
    {
        id: '1.18',
        audio: '/new-audio/chapter-1/1.18.mp3',
        text: 'Pomoc mamie',
        actions: [
            {
                target: '1.19'
            },
            {
                target: '1.20'
            }
        ]
    },
    {
        id: '1.19',
        audio: '/new-audio/chapter-1/1.19.mp3',
        text: 'Pracujesz dalej',
        actions: [
            {
                target: '1.21'
            }
        ]
    },
    {
        id: '1.20',
        audio: '/new-audio/chapter-1/1.20.mp3',
        text: 'Ucinasz drzemkę',
        actions: [
            {
                target: '1.21'
            }
        ]
    },
    {
        id: '1.21',
        audio: '/new-audio/chapter-1/1.21.mp3',
        text: 'Wracasz do pracy',
        actions: [
            {
                target: '1.16',
            },
            {
                target: '1.17'
            }
        ]
    },
    {
        id: '1.22',
        audio: '/new-audio/chapter-1/1.22.mp3',
        text: 'Szukasz dalej',
        actions: [
            {
                target: '1.23'
            },
            {
                target: '1.24'
            }
        ]
    },
    {
        id: '1.23',
        audio: '/new-audio/chapter-1/1.23.mp3',
        text: 'Tacie',
        actions: [
            {
                target: '1.25'
            }
        ]
    },
    {
        id: '1.24',
        audio: '/new-audio/chapter-1/1.24.mp3',
        text: 'Stasiowi',
        actions: [
            {
                target: '1.25'
            }
        ]
    },
    {
        id: '1.25',
        audio: '/new-audio/chapter-1/1.25.mp3',
        text: 'Idziesz na skrzyżowanie',
        actions: [
            {
                target: '1.27'
            }
        ]
    },
    {
        id: '1.26',
        audio: '/new-audio/chapter-1/1.26.mp3',
        text: 'Idziesz na skrzyżowanie',
        actions: [
            {
                target: '1.28'
            }
        ]
    },
    {
        id: '1.27',
        audio: '/new-audio/chapter-1/1.27.mp3',
        text: 'Opowiedz wszystko Stasiowi',
        actions: [
            {
                target: '2.1',
                text: 'Woda'
            },
            {
                target: '3.1',
                text: 'Prąd'
            }
        ]
    },
    {
        id: '1.28',
        audio: '/new-audio/chapter-1/1.28.mp3',
        text: 'Opowiedz wszystko Mani',
        actions: [
            {
                target: '2.1',
                text: 'Woda'
            },
            {
                target: '3.1',
                text: 'Prąd'
            }
        ]
    },
    {
        id: '1.31',
        audio: '/new-audio/chapter-1/1.31.mp3',
        text: 'Mamie',
        actions: [
            {
                target: '1.26'
            }
        ]
    }
];


const CHAPTER_2: GameNode[] = [
    {
        id: '2.1',
        audio: '/new-audio/chapter-2/2.1.mp3',
        text: 'Wstęp - Woda',
        actions: [
            {
                target: '2.2'
            },
            {
                target: '2.25'
            },
            {
                target: '2.36'
            },
            {
                target: '3.1'
            },
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '2.2',
        audio: '/new-audio/chapter-2/2.2.mp3',
        text: 'Sprawdzamy inne działki',
        actions: [
            {
                target: '2.3'
            },
            {
                target: '2.13'
            },
            {
                target: '2.18'
            }
        ]
    },
    {
        id: '2.3',
        audio: '/new-audio/chapter-2/2.3.mp3',
        text: 'Działka Pani Bożeny',
        actions: [
            {
                target: '2.4'
            }
        ]
    },
    {
        id: '2.4',
        audio: '/new-audio/chapter-2/2.4.mp3',
        text: 'Idziemy na działkę',
        actions: [
            {
                target: '2.5'
            },
            {
                target: '2.6'
            }
        ]
    },
    {
        id: '2.5',
        audio: '/new-audio/chapter-2/2.5.mp3',
        text: 'Obserwujemy',
        actions: [
            {
                target: '2.6'
            }
        ]
    },
    {
        id: '2.6',
        audio: '/new-audio/chapter-2/2.6.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '2.7'
            },
            {
                target: '2.10'
            }
        ]
    },
    {
        id: '2.7',
        audio: '/new-audio/chapter-2/2.7.mp3',
        text: 'Pomagamy',
        actions: [
            {
                target: '2.8'
            },
            {
                target: '2.9'
            }
        ]
    },
    {
        id: '2.8',
        audio: '/new-audio/chapter-2/2.8.mp3',
        text: 'Przeszukujemy chatę',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.9',
        audio: '/new-audio/chapter-2/2.9.mp3',
        text: 'Wychodzimy',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.10',
        audio: '/new-audio/chapter-2/2.10.mp3',
        text: 'Wzywamy pomoc',
        actions: [
            {
                target: '2.11'
            },
            {
                target: '2.12'
            }
        ]
    },
    {
        id: '2.11',
        audio: '/new-audio/chapter-2/2.11.mp3',
        text: 'W krzakach',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.12',
        audio: '/new-audio/chapter-2/2.12.mp3',
        text: 'Do chaty',
        actions: [
            {
                target: '2.8'
            },
            {
                target: '2.9'
            }
        ]
    },
    {
        id: '2.13',
        audio: '/new-audio/chapter-2/2.13.mp3',
        text: 'Działka Pana Tadka',
        actions: [
            {
                target: '2.14'
            }
        ]
    },
    {
        id: '2.14',
        audio: '/new-audio/chapter-2/2.14.mp3',
        text: 'Idziemy na działkę',
        actions: [
            {
                target: '2.15'
            },
            {
                target: '2.17'
            }
        ]
    },
    {
        id: '2.15',
        audio: '/new-audio/chapter-2/2.15.mp3',
        text: 'Kąpiemy się',
        actions: [
            {
                target: '2.16'
            }
        ]
    },
    {
        id: '2.16',
        audio: '/new-audio/chapter-2/2.16.mp3',
        text: 'Trzask!',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.17',
        audio: '/new-audio/chapter-2/2.17.mp3',
        text: 'Przeszukujemy chatę',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.18',
        audio: '/new-audio/chapter-2/2.18.mp3',
        text: 'Działka Strzelczyków',
        actions: [
            {
                target: '2.20'
            }
        ]
    },
    {
        id: '2.20',
        audio: '/new-audio/chapter-2/2.20.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '2.21'
            },
            {
                target: '2.23'
            }
        ]
    },
    {
        id: '2.21',
        audio: '/new-audio/chapter-2/2.21.mp3',
        text: 'Płyniemy do zaworu',
        actions: [
            {
                target: '2.22'
            }
        ]
    },
    {
        id: '2.22',
        audio: '/new-audio/chapter-2/2.22.mp3',
        text: 'Ratujemy Juniora!',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.23',
        audio: '/new-audio/chapter-2/2.23.mp3',
        text: 'Ratujemy Juniora!',
        actions: [
            {
                target: '2.24'
            }
        ]
    },
    {
        id: '2.24',
        audio: '/new-audio/chapter-2/2.24.mp3',
        text: 'Wyplątujemy Strzelczyka',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.25',
        audio: '/new-audio/chapter-2/2.25.mp3',
        text: 'Idziemy do zaworu głównego',
        actions: [
            {
                target: '2.28'
            },
            {
                target: '2.26'
            }
        ]
    },
    {
        id: '2.26',
        audio: '/new-audio/chapter-2/2.26.mp3',
        text: 'Głośno',
        actions: [
            {
                target: '2.29'
            },
            {
                target: '2.27'
            }
        ]
    },
    {
        id: '2.27',
        audio: '/new-audio/chapter-2/2.27.mp3',
        text: 'Wchodzimy do domku',
        actions: [
            {
                target: '2.29'
            }
        ]
    },
    {
        id: '2.28',
        audio: '/new-audio/chapter-2/2.28.mp3',
        text: 'Chicho',
        actions: [
            {
                target: '2.29'
            }
        ]
    },
    {
        id: '2.29',
        audio: '/new-audio/chapter-2/2.29.mp3',
        text: 'W stronę odgłosów',
        actions: [
            {
                target: '2.30'
            },
            {
                target: '2.31'
            }
        ]
    },
    {
        id: '2.30',
        audio: '/new-audio/chapter-2/2.30.mp3',
        text: 'Nie pomagamy',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.31',
        audio: '/new-audio/chapter-2/2.31.mp3',
        text: 'Pomagamy',
        actions: [
            {
                target: '2.32'
            },
            {
                target: '2.33'
            },
            {
                target: '2.34'
            }
        ]
    },
    {
        id: '2.32',
        audio: '/new-audio/chapter-2/2.32.mp3',
        text: 'Jak został uszkodzony zawór?',
        actions: [
            {
                target: '2.33.2'
            },
            {
                target: '2.34.2'
            }
        ]
    },
    {
        id: '2.33',
        audio: '/new-audio/chapter-2/2.33.mp3',
        text: 'Kto mógł to zrobić?',
        actions: [
            {
                target: '2.32.2'
            },
            {
                target: '2.34.2'
            }
        ]
    },
    {
        id: '2.34',
        audio: '/new-audio/chapter-2/2.34.mp3',
        text: 'Kiedy to sie mogło stać?',
        actions: [
            {
                target: '2.33.2'
            },
            {
                target: '2.32.2'
            }
        ]
    },
    {
        id: '2.32.2',
        audio: '/new-audio/chapter-2/2.32.mp3',
        text: 'Jak został uszkodzony zawór?',
        actions: [
            {
                target: '2.35'
            }
        ]
    },
    {
        id: '2.33.2',
        audio: '/new-audio/chapter-2/2.33.mp3',
        text: 'Kto mógł to zrobić?',
        actions: [
            {
                target: '2.35'
            }
        ]
    },
    {
        id: '2.34.2',
        audio: '/new-audio/chapter-2/2.34.mp3',
        text: 'Kiedy to sie mogło stać?',
        actions: [
            {
                target: '2.35'
            }
        ]
    },
    {
        id: '2.35',
        audio: '/new-audio/chapter-2/2.35.mp3',
        text: 'Pytamy dalej',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.36',
        audio: '/new-audio/chapter-2/2.36.mp3',
        text: 'Czaimy się przy działających rurach',
        actions: [
            {
                target: '2.37'
            },
            {
                target: '2.44'
            }
        ]
    },
    {
        id: '2.37',
        audio: '/new-audio/chapter-2/2.36.mp3',
        text: 'Blisko miejsca awarii',
        actions: [
            {
                target: '2.38'
            },
            {
                target: '2.41'
            }
        ]
    },
    {
        id: '2.38',
        audio: '/new-audio/chapter-2/2.38.mp3',
        text: 'Pomagamy łapać koty',
        actions: [
            {
                target: '2.40'
            },
            {
                target: '2.39'
            }
        ]
    },
    {
        id: '2.39',
        audio: '/new-audio/chapter-2/2.39.mp3',
        text: 'Oddajemy koty',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.40',
        audio: '/new-audio/chapter-2/2.40.mp3',
        text: 'Nie oddajemy kotów',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.41',
        audio: '/new-audio/chapter-2/2.41.mp3',
        text: 'Nie pomagamy',
        actions: [
            {
                target: '2.42'
            },
            {
                target: '2.43'
            }
        ]
    },
    {
        id: '2.42',
        audio: '/new-audio/chapter-2/2.42.mp3',
        text: 'Nie idziemy',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.43',
        audio: '/new-audio/chapter-2/2.43.mp3',
        text: 'Idziemy za nim',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.44',
        audio: '/new-audio/chapter-2/2.44.mp3',
        text: 'Daleko od awarii',
        actions: [
            {
                target: '2.46'
            },
            {
                target: '2.45'
            }
        ]
    },
    {
        id: '2.45',
        audio: '/new-audio/chapter-2/2.45.mp3',
        text: 'Czatujemy',
        actions: [
            {
                target: '2.1'
            }
        ]
    },
    {
        id: '2.46',
        audio: '/new-audio/chapter-2/2.46.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '2.1'
            }
        ]
    }
]


const CHAPTER_3: GameNode[] = [
    {
        id: '3.1',
        audio: '/new-audio/chapter-3/3.1.mp3',
        text: 'Wstęp - Prąd',
        actions: [
            {
                target: '3.2'
            },
            {
                target: '3.12'
            },
            {
                target: '3.28'
            },
            {
                target: '3.23'
            },
            {
                target: '2.1'
            },
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '3.2',
        audio: '/new-audio/chapter-3/3.2.mp3',
        text: 'Używamy drona',
        actions: [
            {
                target: '3.3'
            },
            {
                target: '3.7'
            }
        ]
    },
    {
        id: '3.3',
        audio: '/new-audio/chapter-3/3.3.mp3',
        text: '"Pożyczamy drona"',
        actions: [
            {
                target: '3.4'
            }
        ]
    },
    {
        id: '3.4',
        audio: '/new-audio/chapter-3/3.4.mp3',
        text: 'Odpalamy drona!',
        actions: [
            {
                target: '3.5'
            },
            {
                target: '3.6'
            }
        ]
    },
    {
        id: '3.5',
        audio: '/new-audio/chapter-3/3.5.mp3',
        text: 'Podchodzimy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.6',
        audio: '/new-audio/chapter-3/3.6.mp3',
        text: 'Obserwujemy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.7',
        audio: '/new-audio/chapter-3/3.7.mp3',
        text: 'Prosimy Stacha',
        actions: [
            {
                target: '3.8'
            }
        ]
    },
    {
        id: '3.8',
        audio: '/new-audio/chapter-3/3.8.mp3',
        text: "Stach odpala drona!",
        actions: [
            {
                target: '3.9'
            },
            {
                target: '3.10'
            }
        ]
    },
    {
        id: '3.9',
        audio: '/new-audio/chapter-3/3.9.mp3',
        text: 'Uciekamy',
        actions: [
            {
                target: '3.11'
            }
        ]
    },
    {
        id: '3.10',
        audio: '/new-audio/chapter-3/3.10.mp3',
        text: 'Ścigamy',
        actions: [
            {
                target: '3.11'
            }
        ]
    },
    {
        id: '3.11',
        audio: '/new-audio/chapter-3/3.11.mp3',
        text: 'Obracamy się',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.12',
        audio: '/new-audio/chapter-3/3.12.mp3',
        text: 'Idziemy do centralnego transformatora',
        actions: [
            {
                target: '3.13'
            },
            {
                target: '3.14'
            }
        ]
    },
    {
        id: '3.13',
        audio: '/new-audio/chapter-3/3.13.mp3',
        text: 'Pomagamy',
        actions: [
            {
                target: '3.16'
            },
            {
                target: '3.15'
            }
        ]
    },
    {
        id: '3.14',
        audio: '/new-audio/chapter-3/3.14.mp3',
        text: 'Nie pomagamy',
        actions: [
            {
                target: '3.16'
            },
            {
                target: '3.15'
            }
        ]
    },
    {
        id: '3.15',
        audio: '/new-audio/chapter-3/3.15.mp3',
        text: 'Olewamy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.16',
        audio: '/new-audio/chapter-3/3.16.mp3',
        text: 'Sprawdzamy',
        actions: [
            {
                target: '3.17'
            },
            {
                target: '3.20'
            }
        ]
    },
    {
        id: '3.17',
        audio: '/new-audio/chapter-3/3.17.mp3',
        text: 'Idziemy frontem',
        actions: [
            {
                target: '3.18'
            },
            {
                target: '3.19'
            }
        ]
    },
    {
        id: '3.18',
        audio: '/new-audio/chapter-3/3.17.mp3',
        text: 'Prawda',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.19',
        audio: '/new-audio/chapter-3/3.17.mp3',
        text: 'Ściema',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.20',
        audio: '/new-audio/chapter-3/3.20.mp3',
        text: 'Czaimy się',
        actions: [
            {
                target: '3.22'
            },
            {
                target: '3.21'
            }
        ]
    },
    {
        id: '3.21',
        audio: '/new-audio/chapter-3/3.21.mp3',
        text: 'Czekamy dalej',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.22',
        audio: '/new-audio/chapter-3/3.22.mp3',
        text: 'Odchodzimy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.23',
        audio: '/new-audio/chapter-3/3.23.mp3',
        text: 'Pytamy sąsiadów',
        actions: [
            {
                target: '3.25'
            },
            {
                target: '3.24'
            }
        ]
    },
    {
        id: '3.24',
        audio: '/new-audio/chapter-3/3.24.mp3',
        text: 'Nie podchodzimy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.25',
        audio: '/new-audio/chapter-3/3.25.mp3',
        text: 'Podchodzimy',
        actions: [
            {
                target: '3.26'
            }
        ]
    },
    {
        id: '3.26',
        audio: '/new-audio/chapter-3/3.26.mp3',
        text: 'Idziemy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.27',
        audio: '/new-audio/chapter-3/3.27.mp3',
        text: 'Nie idziemy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.28',
        audio: '/new-audio/chapter-3/3.28.mp3',
        text: 'Sprawdzam kable',
        actions: [
            {
                target: '3.29'
            },
            {
                target: '3.31'
            }
        ]
    },
    {
        id: '3.29',
        audio: '/new-audio/chapter-3/3.29.mp3',
        text: 'Sami',
        actions: [
            {
                target: '3.30'
            },
            {
                target: '3.31'
            }
        ]
    },
    {
        id: '3.30',
        audio: '/new-audio/chapter-3/3.30.mp3',
        text: 'Olewamy',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.31',
        audio: '/new-audio/chapter-3/3.31.mp3',
        text: 'Prosimy o pomoc',
        actions: [
            {
                target: '3.32'
            }
        ]
    },
    {
        id: '3.32',
        audio: '/new-audio/chapter-3/3.32.mp3',
        text: 'Na górze',
        actions: [
            {
                target: '3.34'
            },
            {
                target: '3.33'
            }
        ]
    },
    {
        id: '3.33',
        audio: '/new-audio/chapter-3/3.33.mp3',
        text: 'Szukamy ostrza',
        actions: [
            {
                target: '3.1'
            }
        ]
    },
    {
        id: '3.34',
        audio: '/new-audio/chapter-3/3.34.mp3',
        text: 'Szukamy ptaka',
        actions: [
            {
                target: '3.1'
            }
        ]
    }
]


const CHAPTER_4: GameNode[] = [
    {
        id: '4.1',
        audio: '/new-audio/chapter-4/4.1.mp3',
        text: 'Wstęp - monitoring',
        actions: [
            {
                target: '4.2'
            },
            {
                target: '4.10'
            },
            {
                target: '4.11'
            },
            {
                target: '4.12'
            }
        ]
    },
    {
        id: '4.2',
        audio: '/new-audio/chapter-4/4.2.mp3',
        text: 'Drzwi frontowe',
        actions: [
            {
                target: '4.3'
            },
            {
                target: '4.4'
            }
        ]
    },
    {
        id: '4.3',
        audio: '/new-audio/chapter-4/4.3.mp3',
        text: 'Prawda',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.4',
        audio: '/new-audio/chapter-4/4.4.mp3',
        text: 'Sciema',
        actions: [
            {
                target: '4.6'
            },
            {
                target: '4.4.1'
            }
        ]
    },
    {
        id: '4.4.1',
        audio: '/new-audio/chapter-4/4.4.1.mp3',
        text: '"My po zgodę formalną!"',
        actions: [
            {
                target: '4.9'
            },
            {
                target: '4.5'
            }
        ]
    },
    {
        id: '4.5',
        audio: '/new-audio/chapter-4/4.5.mp3',
        text: '"Zgoda 17/n28"',
        actions: [
            {
                target: '4.52'
            }
        ]
    },
    {
        id: '4.6',
        audio: '/new-audio/chapter-4/4.6.mp3',
        text: '"Telewizja przyjechała!"',
        actions: [
            {
                target: '4.7'
            },
            {
                target: '4.8'
            }
        ]
    },
    {
        id: '4.7',
        audio: '/new-audio/chapter-4/4.7.mp3',
        text: '"Ekipa Barbary!"',
        actions: [
            {
                target: '4.52'
            }
        ]
    },
    {
        id: '4.8',
        audio: '/new-audio/chapter-4/4.8.mp3',
        text: '"Jacyś ludzie z kamerą!"',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.9',
        audio: '/new-audio/chapter-4/4.9.mp3',
        text: '"Taka ogólna"',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.10',
        audio: '/new-audio/chapter-4/4.10.mp3',
        text: 'Tylne wejście',
        actions: [
            {
                target: '4.43'
            },
            {
                target: '4.47'
            }
        ]
    },
    {
        id: '4.11',
        audio: '/new-audio/chapter-4/4.11.mp3',
        text: 'Otwarte okno',
        actions: [
            {
                target: '4.43'
            },
            {
                target: '4.35'
            }
        ]
    },
    {
        id: '4.12',
        audio: '/new-audio/chapter-4/4.12.mp3',
        text: 'komin',
        actions: [
            {
                target: '4.14'
            },
            {
                target: '4.13'
            },
            {
                target: '4.15'
            }
        ]
    },
    {
        id: '4.13',
        audio: '/new-audio/chapter-4/4.13.mp3',
        text: 'trampolina',
        actions: [
            {
                target: '4.17'
            },
            {
                target: '4.16'
            }
        ]
    },
    {
        id: '4.14',
        audio: '/new-audio/chapter-4/4.14.mp3',
        text: 'drabina',
        actions: [
            {
                target: '4.28'
            },
            {
                target: '4.21'
            }
        ]
    },
    {
        id: '4.15',
        audio: '/new-audio/chapter-4/4.15.mp3',
        text: 'drzewo',
        actions: [
            {
                target: '4.22'
            },
            {
                target: '4.18'
            }
        ]
    },
    {
        id: '4.16',
        audio: '/new-audio/chapter-4/4.16.mp3',
        text: 'Przeciągamy',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.17',
        audio: '/new-audio/chapter-4/4.17.mp3',
        text: 'Skaczemy',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.18',
        audio: '/new-audio/chapter-4/4.18.mp3',
        text: 'Ścigamy się',
        actions: [
            {
                target: '4.20'
            },
            {
                target: '4.19'
            }
        ]
    },
    {
        id: '4.19',
        audio: '/new-audio/chapter-4/4.19.mp3',
        text: '"Budujemy domek na drzewie"',
        actions: [
            {
                target: '4.25'
            }
        ]
    },
    {
        id: '4.20',
        audio: '/new-audio/chapter-4/4.20.mp3',
        text: '"Szukamy nowego latawca"',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.21',
        audio: '/new-audio/chapter-4/4.21.mp3',
        text: 'Ściemniamy',
        actions: [
            {
                target: '4.25'
            }
        ]
    },
    {
        id: '4.22',
        audio: '/new-audio/chapter-4/4.22.mp3',
        text: 'Asekurujemy się',
        actions: [
            {
                target: '4.25'
            }
        ]
    },
    {
        id: '4.23',
        audio: '/new-audio/chapter-4/4.23.mp3',
        text: 'Czekamy',
        actions: [
            {
                target: '4.25'
            }
        ]
    },
    {
        id: '4.25',
        audio: '/new-audio/chapter-4/4.25.mp3',
        text: 'pierwsze piętro',
        actions: [
            {
                target: '4.29'
            },
            {
                target: '4.26'
            }
        ]
    },
    {
        id: '4.26',
        audio: '/new-audio/chapter-4/4.26.mp3',
        text: 'okno',
        actions: [
            {
                target: '4.23'
            },
            {
                target: '4.27'
            }
        ]
    },
    {
        id: '4.27',
        audio: '/new-audio/chapter-4/4.27.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '4.25'
            }
        ]
    },
    {
        id: '4.28',
        audio: '/new-audio/chapter-4/4.28.mp3',
        text: 'Odkładamy drabinę',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.29',
        audio: '/new-audio/chapter-4/4.29.mp3',
        text: 'drabinka',
        actions: [
            {
                target: '4.30'
            },
            {
                target: '4.32'
            },
            {
                target: '4.31'
            }
        ]
    },
    {
        id: '4.30',
        audio: '/new-audio/chapter-4/4.30.mp3',
        text: 'Zawracamy',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.31',
        audio: '/new-audio/chapter-4/4.31.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '4.56'
            }
        ]
    },
    {
        id: '4.32',
        audio: '/new-audio/chapter-4/4.32.mp3',
        text: 'Czekamy',
        actions: [
            {
                target: '4.30'
            },
            {
                target: '4.31'
            }
        ]
    },
    {
        id: '4.34',
        audio: '/new-audio/chapter-4/4.34.mp3',
        text: 'Wchodzimy',
        actions: [
            {
                target: '4.36'
            },
            {
                target: '4.38'
            },
            {
                target: '4.37'
            }
        ]
    },
    {
        id: '4.35',
        audio: '/new-audio/chapter-4/4.35.mp3',
        text: 'Rozglądamy się',
        actions: [
            {
                target: '4.52'
            }
        ]
    },
    {
        id: '4.36',
        audio: '/new-audio/chapter-4/4.36.mp3',
        text: 'Uciekamy',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.37',
        audio: '/new-audio/chapter-4/4.37.mp3',
        text: 'Czekamy',
        actions: [
            {
                target: '4.39'
            },
            {
                target: '4.40'
            }
        ]
    },
    {
        id: '4.38',
        audio: '/new-audio/chapter-4/4.38.mp3',
        text: 'Chowamy się w szafie',
        actions: [
            {
                target: '4.41'
            },
            {
                target: '4.42'
            }
        ]
    },
    {
        id: '4.39',
        audio: '/new-audio/chapter-4/4.39.mp3',
        text: 'Szybko',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.40',
        audio: '/new-audio/chapter-4/4.40.mp3',
        text: 'Wolno',
        actions: [
            {
                target: '4.52'
            }
        ]
    },
    {
        id: '4.41',
        audio: '/new-audio/chapter-4/4.41.mp3',
        text: 'Siedzimy cicho',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.42',
        audio: '/new-audio/chapter-4/4.42.mp3',
        text: 'Miauczymy',
        actions: [
            {
                target: '4.52'
            }
        ]
    },
    {
        id: '4.43',
        audio: '/new-audio/chapter-4/4.43.mp3',
        text: 'W lewo',
        actions: [
            {
                target: '4.44'
            },
            {
                target: '4.45'
            },
            {
                target: '4.46'
            }
        ]
    },
    {
        id: '4.44',
        audio: '/new-audio/chapter-4/4.44.mp3',
        text: 'Do wody',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.45',
        audio: '/new-audio/chapter-4/4.45.mp3',
        text: 'Konfrontujemy się',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.46',
        audio: '/new-audio/chapter-4/4.46.mp3',
        text: 'Pod brezent',
        actions: [
            {
                target: '4.50'
            }
        ]
    },
    {
        id: '4.47',
        audio: '/new-audio/chapter-4/4.47.mp3',
        text: 'W prawo',
        actions: [
            {
                target: '4.48'
            },
            {
                target: '4.49'
            }
        ]
    },
    {
        id: '4.48',
        audio: '/new-audio/chapter-4/4.48.mp3',
        text: 'Skradamy się',
        actions: [
            {
                target: '4.50'
            }
        ]
    },
    {
        id: '4.49',
        audio: '/new-audio/chapter-4/4.49.mp3',
        text: 'Biegniemy',
        actions: [
            {
                target: '4.50'
            }
        ]
    },
    {
        id: '4.50',
        audio: '/new-audio/chapter-4/4.50.mp3',
        text: 'Tylne drzwi',
        actions: [
            {
                target: '4.56'
            }
        ]
    },
    {
        id: '4.52',
        audio: '/new-audio/chapter-4/4.52.mp3',
        text: 'salonik',
        actions: [
            {
                target: '4.53'
            },
            {
                target: '4.55'
            }
        ]
    },
    {
        id: '4.53',
        audio: '/new-audio/chapter-4/4.53.mp3',
        text: 'Drzwi po lewej',
        actions: [
            {
                target: '4.56'
            }
        ]
    },
    {
        id: '4.54',
        audio: '/new-audio/chapter-4/4.54.mp3',
        text: 'Przeszukujemy salonik',
        actions: [
            {
                target: '4.53'
            },
            {
                target: '4.55'
            }
        ]
    },
    {
        id: '4.55',
        audio: '/new-audio/chapter-4/4.55.mp3',
        text: 'Drzwi po prawej',
        actions: [
            {
                target: '4.1'
            }
        ]
    },
    {
        id: '4.56',
        audio: '/new-audio/chapter-4/4.56.mp3',
        text: 'Wąskie przejście',
        actions: [
            {
                target: '4.57'
            },
            {
                target: '4.58'
            },
            {
                target: '4.59'
            },
            {
                target: '4.60'
            }
        ]
    },
    {
        id: '4.57',
        audio: '/new-audio/chapter-4/4.57.mp3',
        text: 'Lustro',
        actions: [
            {
                target: '4.56'
            }
        ]
    },
    {
        id: '4.58',
        audio: '/new-audio/chapter-4/4.58.mp3',
        text: 'Przeszukujmemy przejście',
        actions: [
            {
                target: '4.61'
            }
        ]
    },
    {
        id: '4.59',
        audio: '/new-audio/chapter-4/4.59.mp3',
        text: 'Płachta',
        actions: [
            {
                target: '4.56'
            }
        ]
    },
    {
        id: '4.60',
        audio: '/new-audio/chapter-4/4.60.mp3',
        text: 'Laser',
        actions: [
            {
                target: '4.61'
            }
        ]
    },
    {
        id: '4.61',
        audio: '/new-audio/chapter-4/4.61.mp3',
        text: 'Drzwi do monitoringu',
        actions: [
            {
                target: '4.62'
            },
            {
                target: '4.64'
            },
            {
                target: '4.63'
            }
        ]
    },
    {
        id: '4.62',
        audio: '/new-audio/chapter-4/4.62.mp3',
        text: 'Spinką',
        actions: [
            {
                target: '4.61'
            }
        ]
    },
    {
        id: '4.63',
        audio: '/new-audio/chapter-4/4.63.mp3',
        text: 'Z kopa',
        actions: [
            {
                target: '4.61'
            }
        ]
    },
    {
        id: '4.64',
        audio: '/new-audio/chapter-4/4.64.mp3',
        text: 'klamką',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.65',
        audio: '/new-audio/chapter-4/4.65.mp3',
        text: 'Monitoring',
        actions: [
            {
                target: '4.66'
            },
            {
                target: '4.67'
            },
            {
                target: '4.68'
            },
            {
                target: '4.69'
            },
            {
                target: '4.70'
            },
            {
                target: '4.71'
            },
            {
                target: '4.72'
            },
            {
                target: '4.73'
            },
            {
                target: '4.74'
            },
            {
                target: '4.75'
            }
        ]
    },
    {
        id: '4.66',
        audio: '/new-audio/chapter-4/4.66.mp3',
        text: 'DOLNA + PORANEK',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.67',
        audio: '/new-audio/chapter-4/4.67.mp3',
        text: 'DOLNA + POŁUDNIE',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.68',
        audio: '/new-audio/chapter-4/4.68.mp3',
        text: 'DOLNA + WIECZÓR',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.69',
        audio: '/new-audio/chapter-4/4.69.mp3',
        text: 'ŚRODKOWA + PORANEK',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.70',
        audio: '/new-audio/chapter-4/4.70.mp3',
        text: 'ŚRODKOWA + POŁUDNIE',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.71',
        audio: '/new-audio/chapter-4/4.71.mp3',
        text: 'ŚRODKOWA + WIECZÓR',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.72',
        audio: '/new-audio/chapter-4/4.72.mp3',
        text: 'GÓRNA + PORANEK',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.73',
        audio: '/new-audio/chapter-4/4.73.mp3',
        text: 'GÓRNA + POŁUDNIE',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.74',
        audio: '/new-audio/chapter-4/4.74.mp3',
        text: 'GÓRNA + WIECZÓR',
        actions: [
            {
                target: '4.65'
            }
        ]
    },
    {
        id: '4.75',
        audio: '/new-audio/chapter-4/4.75.mp3',
        text: 'Koniec',
        actions: [
            {
                target: '5.1'
            }
        ]
    },
]


const CHAPTER_5: GameNode[] = [
    {
        id: '5.1',
        audio: '/new-audio/chapter-5/5.1.mp3',
        text: 'Wstęp - Walne Zgromadzenie',
        actions: [
            {
                target: '5.2'
            },
            {
                target: '5.13'
            }
        ]
    },
    {
        id: '5.2',
        audio: '/new-audio/chapter-5/5.2.mp3',
        text: 'Sprawdzmy działki',
        actions: [
            {
                target: '5.3'
            },
            {
                target: '5.4'
            },
            {
                target: '5.5'
            },
            {
                target: '5.6'
            },
            {
                target: '5.7'
            },
            {
                target: '5.8'
            },
            {
                target: '5.9'
            },
            {
                target: '5.10'
            },
            {
                target: '5.11'
            },
            {
                target: '5.12'
            },
        ]
    },
    {
        id: '5.3',
        audio: '/new-audio/chapter-5/5.3.mp3',
        text: 'Działka Bożenki',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.4',
        audio: '/new-audio/chapter-5/5.4.mp3',
        text: 'Działka Zygmunta',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.5',
        audio: '/new-audio/chapter-5/5.5.mp3',
        text: 'Działka Prezesa',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.6',
        audio: '/new-audio/chapter-5/5.6.mp3',
        text: 'Działka Strzelczyków',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.7',
        audio: '/new-audio/chapter-5/5.7.mp3',
        text: 'Działka Tadka',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.8',
        audio: '/new-audio/chapter-5/5.8.mp3',
        text: 'Działka Marii',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.9',
        audio: '/new-audio/chapter-5/5.9.mp3',
        text: 'Działka Jadzi',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.10',
        audio: '/new-audio/chapter-5/5.10.mp3',
        text: 'Działka Zbyszka',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.11',
        audio: '/new-audio/chapter-5/5.11.mp3',
        text: 'Działka Wincenta',
        actions: [
            {
                target: '5.2'
            }
        ]
    },
    {
        id: '5.12',
        audio: '/new-audio/chapter-5/5.12.mp3',
        text: 'Biegniemy na zgromadzenie',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.13',
        audio: '/new-audio/chapter-5/5.13.mp3',
        text: 'Idziemy na zgromadzenie',
        actions: [
            {
                target: '5.14'
            },
            {
                target: '5.15'
            }
        ]
    },
    {
        id: '5.14',
        audio: '/new-audio/chapter-5/5.14.mp3',
        text: 'Czekamy na zewnątrz',
        actions: [
            {
                target: '5.32'
            },
            {
                target: '5.25'
            }
        ]
    },
    {
        id: '5.15',
        audio: '/new-audio/chapter-5/5.15.mp3',
        text: 'Wchodzimy do środka',
        actions: [
            {
                target: '5.16'
            },
            {
                target: '5.22'
            },
            {
                target: '5.18'
            },
            {
                target: '5.17'
            }
        ]
    },
    {
        id: '5.16',
        audio: '/new-audio/chapter-5/5.16.mp3',
        text: 'Wracamy na zewnątrz',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.17',
        audio: '/new-audio/chapter-5/5.17.mp3',
        text: 'Do szafy',
        actions: [
            {
                target: '5.37'
            }
        ]
    },
    {
        id: '5.18',
        audio: '/new-audio/chapter-5/5.28.mp3',
        text: 'Pod stół',
        actions: [
            {
                target: '5.20'
            },
            {
                target: '5.19'
            }
        ]
    },
    {
        id: '5.19',
        audio: '/new-audio/chapter-5/5.19.mp3',
        text: 'Zostawiamy w spokoju',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.20',
        audio: '/new-audio/chapter-5/5.20.mp3',
        text: 'Odstraszamy kota',
        actions: [
            {
                target: '5.21'
            }
        ]
    },
    {
        id: '5.21',
        audio: '/new-audio/chapter-5/5.21.mp3',
        text: 'Zostajemy pod stołem',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.22',
        audio: '/new-audio/chapter-5/5.22.mp3',
        text: 'Za drzwi',
        actions: [
            {
                target: 'Za drzwi'
            }
        ]
    },
    {
        id: '5.23',
        audio: '/new-audio/chapter-5/5.23.mp3',
        text: 'Za lewym',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.24',
        audio: '/new-audio/chapter-5/5.24.mp3',
        text: 'Za prawym',
        actions: [
            {
                target: '5.37'
            }
        ]
    },
    {
        id: '5.25',
        audio: '/new-audio/chapter-5/5.25.mp3',
        text: 'Wchodzimy frontowymi drzwiami',
        actions: [
            {
                target: '5.29'
            },
            {
                target: '5.26'
            }
        ]
    },
    {
        id: '5.26',
        audio: '/new-audio/chapter-5/5.26.mp3',
        text: 'Za drzwi 2',
        actions: [
            {
                target: '5.27'
            },
            {
                target: '5.28'
            }
        ]
    },
    {
        id: '5.27',
        audio: '/new-audio/chapter-5/5.27.mp3',
        text: 'Za prawym 2',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.28',
        audio: '/new-audio/chapter-5/5.28.mp3',
        text:' Za lewym 2',
        actions: [
            {
                target: '5.37'
            }
        ]
    },
    {
        id: '5.29',
        audio: '/new-audio/chapter-5/5.29.mp3',
        text: 'Przemykamy się do środka',
        actions: [
            {
                target: '5.31'
            },
            {
                target: '5.30'
            }
        ]
    },
    {
        id: '5.30',
        audio: '/new-audio/chapter-5/5.30.mp3',
        text: 'Pod stół 2',
        actions: [
            {
                target: '5.31'
            },
            {
                target: '5.26'
            }
        ]
    },
    {
        id: '5.31',
        audio: '/new-audio/chapter-5/5.31.mp3',
        text: 'Do szafy 2',
        actions: [
            {
                target: '5.37'
            }
        ]
    },
    {
        id: '5.32',
        audio: '/new-audio/chapter-5/5.32.mp3',
        text: 'Szukamy okna',
        actions: [
            {
                target: '5.33'
            },
            {
                target: '5.34'
            }
        ]
    },
    {
        id: '5.33',
        audio: '/new-audio/chapter-5/5.33.mp3',
        text: 'W lewo',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.34',
        audio: '/new-audio/chapter-5/5.34.mp3',
        text: 'W prawo',
        actions: [
            {
                target: '5.35'
            },
            {
                target: '5.36'
            }
        ]
    },
    {
        id: '5.35',
        audio: '/new-audio/chapter-5/5.35.mp3',
        text: 'Idziemy do drugiego okna',
        actions: [
            {
                target: '5.14'
            }
        ]
    },
    {
        id: '5.36',
        audio: '/new-audio/chapter-5/5.36.mp3',
        text: 'Wskakujemy do środka',
        actions: [
            {
                target: '5.31'
            },
            {
                target: '5.30'
            }
        ]
    },
    {
        id: '5.37',
        audio: '/new-audio/chapter-5/5.37.mp3',
        text: 'Walne zgromadzenie',
        actions: [
            {
                target: '5.38'
            },
            {
                target: '5.39'
            }
        ]
    },
    {
        id: '5.38',
        audio: '/new-audio/chapter-5/5.38.mp3',
        text: 'Wkraczamy',
        actions: [
            {
                target: '5.41'
            },
            {
                target: '5.42'
            },
            {
                target: '5.43'
            },
            {
                target: '5.44'
            },
            {
                target: '5.45'
            },
            {
                target: '5.46'
            },
            {
                target: '5.47'
            },
            {
                target: '5.48'
            },
            {
                target: '5.49'
            }
        ]
    },
    {
        id: '5.39',
        audio: '/new-audio/chapter-5/5.39.mp3',
        text: 'Czekamy',
        actions: [
            {
                target: '5.38'
            },
            {
                target: '5.40'
            }
        ]
    },
    {
        id: '5.40',
        audio: '/new-audio/chapter-5/5.40.mp3',
        text: 'Czekamy dalej',
        actions: [
            {
                target: '5.38'
            }
        ]
    },
    {
        id: '5.41',
        audio: '/new-audio/chapter-5/5.41.mp3',
        text: '"To Wincent"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.42',
        audio: '/new-audio/chapter-5/5.42.mp3',
        text: '"To Strzelczyki"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.43',
        audio: '/new-audio/chapter-5/5.43.mp3',
        text: '"To Tadek"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.44',
        audio: '/new-audio/chapter-5/5.44.mp3',
        text: '"To Maria"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.45',
        audio: '/new-audio/chapter-5/5.45.mp3',
        text: '"To Jadzia"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.46',
        audio: '/new-audio/chapter-5/5.46.mp3',
        text: '"To Zbyszek"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.47',
        audio: '/new-audio/chapter-5/5.47.mp3',
        text: '"To Bożenka"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.48',
        audio: '/new-audio/chapter-5/5.48.mp3',
        text: '"To Zygmunt"',
        actions: [
            {
                target: '5.50'
            }
        ]
    },
    {
        id: '5.49',
        audio: '/new-audio/chapter-5/5.49.mp3',
        text: '"To prezes"',
        actions: [
            {
                target: '5.51'
            }
        ]
    },
    {
        id: '5.50',
        audio: '/new-audio/chapter-5/5.50.mp3',
        text: 'Zakończenie 1',
        actions: [
            {
                target: '5.53'
            }
        ]
    },
    {
        id: '5.51',
        audio: '/new-audio/chapter-5/5.51.mp3',
        text: 'Zakończenie 2',
        actions: [
            {
                target: '5.53'
            }
        ]
    },
    {
        id: '5.53',
        audio: '/new-audio/chapter-5/5.53.2.mp3',
        text: '...i jescze ostatnia rzecz...',
        actions: []
    }
]


export const gameTree: GameNode[] = [
    ...CHAPTER_1,
    ...CHAPTER_2,
    ...CHAPTER_3,
    ...CHAPTER_4,
    ...CHAPTER_5
]
