import { useMainModuleState } from ".."
import { gameTree } from "../game-tree";

export default function useActiveNode() {
    const {activeNode, lastAction} = useMainModuleState();

    console.log(JSON.stringify(gameTree));

    return {
        gameNode: useNode(activeNode),
        lastAction: lastAction
    }
}

export function useNode(id: string) {
    const gameNode = gameTree.find(n => n.id === id)

    if (gameNode) {
        gameNode.actions.forEach((a) => {
            if (!a.text) {
                const target = gameTree.find(n => n.id === a.target);

                a.text = target?.text || 'NIEZNANY WĘZEŁ DOCELOWY'
            }
        });
    }

    return gameNode;
}
