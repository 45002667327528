import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";

import playSrc from '../../assets/player/play.png';
import playInactiveSrc from '../../assets/player/play-inactive.png';
import pauseSrc from '../../assets/player/pause.png';
import ProgressBar from './progress';
import PlayerController, { PlayerControllerAPI, PlayerState } from "./controller";
import ResetButton from "../reset-button";

const Container = styled.div`

    width: 100%;

    .standard-player {
        overflow: hidden;

        width: 100%;
        background: white;

        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(12, 1fr);

        .buttons-row {
            max-width: 512px;
            width: 95%;
            margin: 0 auto;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column: span 12;
        }

        .progress-row {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 1fr 1fr 1fr;

            grid-row: 2;
            grid-column-start: 3;
            grid-column-end: 11;
        }

        .player-button-container {
            grid-row: 1;
            grid-column: 2;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .reset-button-container {
            grid-row: 1;
            grid-column: 3;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .progress-container {
            grid-row: 2;
        }

        .time-info-container {
            grid-row: 3;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        @media (max-width: 800px) {
            .progress-row {
                grid-column-start: 2;
                grid-column-end: 12;
            }
        }
    }

    .mini-player {
        display: none;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(12, 1fr);

        background: white;


        .player-button-container {
            grid-row: 1;
            grid-column-start: 1;
            grid-column-end: 3;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .reset-button-container {
            grid-row: 1;
            grid-column-start: 11;
            grid-column-end: 13;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .progress-row {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 1fr 1fr 1fr;

            grid-row: 1;
            grid-column-start: 3;
            grid-column-end: 11;
        }

        .progress-container {
            grid-row: 2;
        }

        .time-info-container {
            grid-row: 3;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    @media (orientation: landscape) and (max-height: 500px) {
        .standard-player {
            display: none;
        }
        .mini-player {
            display: grid;
        }
    }

    .error {
        color: red;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        p {
            padding: 0;
            margin: 0;
            line-height: 1.5em;
        }
    }
`;

const PlayerButton = styled.span`
    display: inline-block;

    width: 100px;
    height: 100px;

    text-indent: -9999px;
    font-size: 0;

    margin: 0 6px;

    outline: none;

    cursor: pointer;
`;

const PlayButton = styled(PlayerButton) <{ active: boolean }>`
    background: url(${({ active }) => active ? playSrc : playInactiveSrc}) 50% 50% no-repeat;
    background-size: contain;
`;

const PauseButton = styled(PlayerButton) <{ active: boolean }>`
    background: url(${({ active }) => active ? pauseSrc : pauseSrc}) 50% 50% no-repeat;
    background-size: contain;
`;

type Props = {
    src: string
}
export default function AudioPlayer({ src }: Props) {

    const player = useRef<HTMLAudioElement>(null);
    const playerController = useRef<PlayerControllerAPI>();

    const [state, setState] = useState<PlayerState>({
        current: 0,
        duration: 0,
        isPlaying: false,
        isPaused: false,
        playAvailable: false,
        pauseAvailable: false,
        seekAvailable: false,
        error: null
    });

    const onPlayClicked = useCallback(() => {
        playerController.current?.play();
    }, []);

    const onPauseClicked = useCallback(() => {
        playerController.current?.pause();
    }, []);

    const seekTo = useCallback((value: number) => {
        playerController.current?.seek(value);
    }, []);

    useEffect(() => {
        if (player.current) {
            const playerEl = player.current;
            playerController.current = PlayerController(playerEl, setState);

            return () => {
                if (playerController.current) {
                    playerController.current.destroy();
                }
            }
        }
    }, [setState]);

    const timeInfo = useMemo(() => {

        const duration = state.duration | 0;

        const durationMin = (duration / 60) | 0;
        const durationSec = duration % 60;
        const durSecStr = durationSec < 10 ? `0${durationSec}` : durationSec;

        const current = state.current | 0;

        const currentMin = (current / 60) | 0;
        const currentSec = current % 60;
        const curSecStr = currentSec < 10 ? `0${currentSec}` : currentSec;


        return `${currentMin}:${curSecStr}/${durationMin}:${durSecStr}`;
    }, [state]);

    return (
        <Container>
            {!state.error && <>
                <div className="standard-player">
                    <div className="buttons-row">
                        <div className="player-button-container">
                            {state.playAvailable && <PlayButton active={true} role="button" tabIndex={0} onClick={onPlayClicked}>Play</PlayButton>}
                            {state.pauseAvailable && <PauseButton active={true} role="button" tabIndex={0} onClick={onPauseClicked}>Pause</PauseButton>}
                        </div>
                        <div className='reset-button-container'>
                            <ResetButton />
                        </div>
                    </div>
                    <div className="progress-row">
                        <div className="progress-container">
                            {state.seekAvailable && <ProgressBar current={state.current || 0} full={state.duration || 0} onChange={seekTo} />}
                        </div>
                        <div className="time-info-container">
                            {timeInfo}
                        </div>
                    </div>
                </div>
                <div className="mini-player">
                    <div className="player-button-container">
                        {state.playAvailable && <PlayButton active={true} role="button" tabIndex={0} onClick={onPlayClicked}>Play</PlayButton>}
                        {state.pauseAvailable && <PauseButton active={true} role="button" tabIndex={0} onClick={onPauseClicked}>Pause</PauseButton>}
                    </div>
                    <div className='reset-button-container'>
                        <ResetButton />
                    </div>
                    <div className="progress-row">
                        <div className="progress-container">
                            {state.seekAvailable && <ProgressBar current={state.current || 0} full={state.duration || 0} onChange={seekTo} />}
                        </div>
                        <div className="time-info-container">
                            {timeInfo}
                        </div>
                    </div>
                </div>
            </>}
            {state.error && <div className='error'>
                <p>Wystąpił nieoczekiwany błąd. {src}</p>
                <p>Proszę odświerzyć przeglądarkę. Jeżeli problem się powtórzy proszę zgłosić do osoby kontaktowej.</p>
                <p>{state.error}</p>
            </div>}
            <audio autoPlay={true} src={src} ref={player} />
        </Container>
    )
}
